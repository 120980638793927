// src/utils/auth.js

import axios from 'axios';

const apiBaseUrl = process.env.NODE_ENV === 'production'
  ? 'https://list.gqfam.com/api'
  : 'http://localhost:7000/api';

export const checkAndRefreshToken = async () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    // Verify token validity with the server
    await axios.get(`${apiBaseUrl}/auth/verify`, {
      headers: { 'x-auth-token': token }
    });
    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      // Token is invalid or expired, attempt to refresh
      try {
        const response = await axios.post(`${apiBaseUrl}/auth/refresh`, {}, {
          headers: { 'x-auth-token': token }
        });
        
        // Update tokens in localStorage
        localStorage.setItem('token', response.data.token);
        return true;
      } catch (refreshError) {
        // If refresh fails, clear localStorage and return false
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        return false;
      }
    }
    return false;
  }
};

export const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        
        try {
          const refreshed = await checkAndRefreshToken();
          if (refreshed) {
            originalRequest.headers['x-auth-token'] = localStorage.getItem('token');
            return axios(originalRequest);
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      
      return Promise.reject(error);
    }
  );
};
