import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        bg: '#111111',
        color: 'gray.100',
      },
    },
  },
  colors: {
    gray: {
      850: '#1a1a1a',
      900: '#121212',
    },
  },
  components: {
    Button: {
      // Remove the defaultProps to allow individual button colors
      variants: {
        solid: props => ({
          bg: `${props.colorScheme}.500`,
          color: 'white',
          _hover: {
            bg: `${props.colorScheme}.600`,
          },
        }),
        ghost: props => ({
          bg: 'transparent',
          color: `${props.colorScheme}.500`,
          _hover: {
            bg: `${props.colorScheme}.100`,
            opacity: 0.3,
          },
        }),
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: 'gray.850',
            borderColor: 'gray.600',
            _hover: {
              borderColor: 'purple.400',
            },
            _focus: {
              borderColor: 'purple.500',
              boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)',
            },
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: 'gray.850',
            borderColor: 'gray.600',
            _hover: {
              borderColor: 'purple.400',
            },
            _focus: {
              borderColor: 'purple.500',
              boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)',
            },
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: 'gray.850',
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          bg: 'gray.850',
        },
      },
    },
  },
});

export default theme;
