import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';

const AddItem = ({ onItemAdded }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isSectionModalOpen, 
    onOpen: onSectionModalOpen, 
    onClose: onSectionModalClose 
  } = useDisclosure();
  
  const [stores, setStores] = useState([]);
  const [sections, setSections] = useState([]);
  const [uncategorizedId, setUncategorizedId] = useState(null);
  const [newItem, setNewItem] = useState({
    store: '',
    itemName: '',
    imageURL: '',
    section: '',
  });
  const [newSection, setNewSection] = useState({
    sectionName: ''
  });
  const [isAddingSectionLoading, setIsAddingSectionLoading] = useState(false);
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    fetchStores();
    fetchSections();
  }, []);

  const fetchStores = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(res.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: "Unable to load stores. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchSections = async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/sections`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSections(res.data);
      
      // Find and set the Uncategorized section ID
      const uncategorized = res.data.find(section => section.sectionName === 'Uncategorized');
      if (uncategorized) {
        setUncategorizedId(uncategorized._id);
        // Set as default for new items
        setNewItem(prev => ({
          ...prev,
          section: uncategorized._id
        }));
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
      toast({
        title: "Error fetching sections",
        description: "Unable to load sections. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'section' && value === 'new') {
      onSectionModalOpen();
      return;
    }
    setNewItem({ ...newItem, [name]: value });
  };

  const handleNewSectionInputChange = (e) => {
    setNewSection({ sectionName: e.target.value });
  };

  const handleAddSection = async () => {
    try {
      setIsAddingSectionLoading(true);
      
      const response = await axios.post(
        `${apiBaseUrl}/sections`,
        { sectionName: newSection.sectionName },
        { headers: { 'x-auth-token': localStorage.getItem('token') }}
      );

      // Update sections list and set the new section as selected
      await fetchSections();
      setNewItem({ ...newItem, section: response.data._id });

      toast({
        title: "Section added successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onSectionModalClose();
      setNewSection({ sectionName: '' });
    } catch (error) {
      toast({
        title: "Error adding section",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsAddingSectionLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${apiBaseUrl}/items`, newItem, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      onItemAdded(res.data);
      onClose();
      setNewItem({ store: '', itemName: '', imageURL: '', section: uncategorizedId });
      toast({
        title: "Item added successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const modalSize = useBreakpointValue({ base: "full", md: "md" });

  return (
    <>
      <Box 
        display="flex" 
        alignItems="flex-end"
        width="100%"
      >
        <Button 
          onClick={onOpen} 
          colorScheme="blue" 
          size={buttonSize}
          width="100%"
          height="52px"
          mt={1}
          fontSize="lg"
          fontWeight="semibold"
          bg="blue.500"
          _hover={{ bg: 'blue.600' }}
        >
          Add New Item
        </Button>
      </Box>

      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size={modalSize}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">Add New Item</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel color="white">Item Name</FormLabel>
                <Input 
                  name="itemName" 
                  value={newItem.itemName} 
                  onChange={handleInputChange} 
                  placeholder="Enter item name"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel color="white">Section</FormLabel>
                <Select
                  name="section"
                  value={newItem.section}
                  onChange={handleInputChange}
                  bg="gray.700"
                  color="white"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                >
                  {/* Uncategorized always first */}
                  {sections
                    .filter(section => section.sectionName === 'Uncategorized')
                    .map(section => (
                      <option 
                        key={section._id} 
                        value={section._id}
                        style={{ background: '#1a1a1a', color: 'gray' }}
                      >
                        {section.sectionName}
                      </option>
                    ))}
                    
                  {/* Add New Section option */}
                  <option 
                    value="new" 
                    style={{ 
                      background: '#1a1a1a', 
                      color: 'lightgreen',
                      fontWeight: 'bold'
                    }}
                  >
                    + Add New Section
                  </option>

                  {/* Divider */}
                  <option 
                    value="" 
                    disabled 
                    style={{ 
                      background: '#1a1a1a',
                      borderTop: '1px solid gray',
                      borderBottom: '1px solid gray',
                      color: 'gray'
                    }}
                  >
                    -------------
                  </option>

                  {/* All other sections */}
                  {sections
                    .filter(section => section.sectionName !== 'Uncategorized')
                    .sort((a, b) => a.sectionName.localeCompare(b.sectionName))
                    .map(section => (
                      <option 
                        key={section._id} 
                        value={section._id}
                        style={{ background: '#1a1a1a', color: 'white' }}
                      >
                        {section.sectionName}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel color="white">Store</FormLabel>
                <Select 
                  name="store" 
                  value={newItem.store} 
                  onChange={handleInputChange}
                  placeholder="Select a store"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                >
                  {stores.map(store => (
                    <option 
                      key={store._id} 
                      value={store._id}
                      style={{ background: '#1a1a1a', color: 'white' }}
                    >
                      {store.storeName}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color="white">Image URL</FormLabel>
                <Input 
                  name="imageURL" 
                  value={newItem.imageURL} 
                  onChange={handleInputChange}
                  placeholder="Enter image URL (optional)"
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleSubmit}
              size={buttonSize}
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
            >
              Add Item
            </Button>
            <Button 
              onClick={onClose}
              size={buttonSize}
              variant="ghost"
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add New Section Modal */}
      <Modal
        isOpen={isSectionModalOpen}
        onClose={onSectionModalClose}
        size="md"
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">Add New Section</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel color="white">Section Name</FormLabel>
              <Input
                name="sectionName"
                value={newSection.sectionName}
                onChange={handleNewSectionInputChange}
                placeholder="Enter section name"
                bg="gray.700"
                color="white"
                borderColor="gray.600"
                _hover={{ borderColor: 'purple.400' }}
                _focus={{ 
                  borderColor: 'purple.500',
                  boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleAddSection}
              isLoading={isAddingSectionLoading}
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
            >
              Add Section
            </Button>
            <Button
              variant="ghost"
              onClick={onSectionModalClose}
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddItem;