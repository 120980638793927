import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Image,
  VStack,
  HStack,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Switch,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';

const DEFAULT_IMAGE = '/images/nia.png';

const ItemModal = ({ isOpen, onClose, item, onUpdate, onDelete }) => {
  const [editedItem, setEditedItem] = useState(item);
  const [stores, setStores] = useState([]);
  const [sections, setSections] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isStoreExclusive, setIsStoreExclusive] = useState(false);
  const [newSection, setNewSection] = useState({ sectionName: '' });
  const [isAddingSectionLoading, setIsAddingSectionLoading] = useState(false);
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const toast = useToast();
  
  const { 
    isOpen: isDeleteAlertOpen, 
    onOpen: onDeleteAlertOpen, 
    onClose: onDeleteAlertClose 
  } = useDisclosure();

  const { 
    isOpen: isSectionModalOpen, 
    onOpen: onSectionModalOpen, 
    onClose: onSectionModalClose 
  } = useDisclosure();

  const cancelRef = React.useRef();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  const fetchStores = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(res.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: "Unable to load stores",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [apiBaseUrl, toast]);

  const fetchSections = useCallback(async () => {
    try {
      const res = await axios.get(`${apiBaseUrl}/sections`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSections(res.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
      toast({
        title: "Error fetching sections",
        description: "Unable to load sections",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [apiBaseUrl, toast]);

  useEffect(() => {
    setEditedItem(item);
    setImageError(false);
    setIsStoreExclusive(item?.isExclusive || false);
    fetchStores();
    fetchSections();
  }, [item, fetchStores, fetchSections]);

  const handleImageError = () => {
    setImageError(true);
  };

  const imageUrl = (!editedItem?.imageURL || imageError) ? DEFAULT_IMAGE : editedItem.imageURL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'section' && value === 'new') {
      onSectionModalOpen();
      return;
    }
    if (name === 'imageURL') {
      setImageError(false);
    }
    // Prevent store change if exclusive is toggled on
    if (name === 'store' && isStoreExclusive) {
      return;
    }
    setEditedItem(prev => ({
      ...prev,
      [name]: value === '' ? null : value
    }));
  };

  const handleNewSectionInputChange = (e) => {
    setNewSection({ sectionName: e.target.value });
  };

  const handleAddSection = async () => {
    try {
      setIsAddingSectionLoading(true);
      
      const response = await axios.post(
        `${apiBaseUrl}/sections`,
        { sectionName: newSection.sectionName },
        { headers: { 'x-auth-token': localStorage.getItem('token') }}
      );

      // Update sections list and set the new section as selected
      await fetchSections();
      setEditedItem(prev => ({ ...prev, section: response.data._id }));

      toast({
        title: "Section added successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onSectionModalClose();
      setNewSection({ sectionName: '' });
    } catch (error) {
      toast({
        title: "Error adding section",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsAddingSectionLoading(false);
    }
  };

  const handleStoreExclusiveToggle = (e) => {
    setIsStoreExclusive(e.target.checked);
    setEditedItem((prev) => ({
      ...prev,
      isExclusive: e.target.checked,
    }));
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await onDelete(item._id);
      onDeleteAlertClose();
      onClose();
    } catch (error) {
      console.error('Error deleting item:', error);
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleWantThis = async () => {
    try {
      setIsUpdating(true);
      const response = await axios.post(
        `${apiBaseUrl}/items/${item._id}/want`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setEditedItem(response.data);
      if (onUpdate) {
        await onUpdate(response.data);
      }
      toast({
        title: "Added to your wanted items",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding want",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      
      if (!editedItem?.itemName?.trim()) {
        toast({
          title: "Item name is required",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      if (!editedItem?.store) {
        toast({
          title: "Store selection is required",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      await onUpdate(editedItem);
      
      toast({
        title: "Item updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const modalButtons = () => {
    const baseButtons = [
      <Button 
        key="back"
        colorScheme="blue" 
        onClick={onClose} 
        flex="1"
        _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
        _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
      >
        Back
      </Button>
    ];

    if (isAdmin) {
      baseButtons.push(
        <Button 
          key="save"
          colorScheme="green" 
          onClick={handleSave}
          flex="1"
          isLoading={isUpdating}
          _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
          _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
        >
          Save
        </Button>,
        <Button 
          key="delete"
          colorScheme="red" 
          onClick={onDeleteAlertOpen}
          flex="1"
          _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
          _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
        >
          Delete
        </Button>
      );
    }

    return baseButtons;
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        closeOnOverlayClick={true}
        isCentered
        size={{ base: "full", md: "2xl" }}
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">{editedItem?.itemName}</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack spacing={4}>
              <HStack width="100%" spacing={4} align="start">
                <VStack flex="1" spacing={4} align="stretch">
                  <FormControl isRequired>
                    <FormLabel color="white">Item Name</FormLabel>
                    <Input 
                      name="itemName" 
                      value={editedItem?.itemName || ''} 
                      onChange={handleInputChange} 
                      bg="gray.700"
                      color="white"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="white">Section</FormLabel>
                    <Select
                      name="section"
                      value={editedItem?.section?._id || editedItem?.section || ''}
                      onChange={handleInputChange}
                      bg="gray.700"
                      color="white"
                      placeholder="Select a section"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    >
                      {/* Uncategorized always first */}
                      {sections
                        .filter(section => section.sectionName === 'Uncategorized')
                        .map(section => (
                          <option 
                            key={section._id} 
                            value={section._id}
                            style={{ background: '#1a1a1a', color: 'gray' }}
                          >
                            {section.sectionName}
                          </option>
                        ))}
                        
                      {/* Add New Section option */}
                      <option 
                        value="new" 
                        style={{ 
                          background: '#1a1a1a', 
                          color: 'lightgreen',
                          fontWeight: 'bold'
                        }}
                      >
                        + Add New Section
                      </option>

                      {/* Divider */}
                      <option 
                        value="" 
                        disabled 
                        style={{ 
                          background: '#1a1a1a',
                          borderTop: '1px solid gray',
                          borderBottom: '1px solid gray',
                          color: 'gray'
                        }}
                      >
                        -------------
                      </option>

                      {/* All other sections */}
                      {sections
                        .filter(section => section.sectionName !== 'Uncategorized')
                        .sort((a, b) => a.sectionName.localeCompare(b.sectionName))
                        .map(section => (
                          <option 
                            key={section._id} 
                            value={section._id}
                            style={{ background: '#1a1a1a', color: 'white' }}
                          >
                            {section.sectionName}
                          </option>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl isRequired>
                    <HStack justify="space-between" align="center">
                      <FormLabel color="white" mb={0}>Store</FormLabel>
                      <Tooltip 
                        label={isStoreExclusive ? "Store cannot be changed while locked" : "Lock store selection"}
                        placement="top"
                      >
                        <HStack spacing={2}>
                          <Box color="white" fontSize="sm">Exclusive</Box>
                          <Switch 
                            colorScheme="purple"
                            isChecked={isStoreExclusive}
                            onChange={handleStoreExclusiveToggle}
                          />
                        </HStack>
                      </Tooltip>
                    </HStack>
                    <Select
                      name="store"
                      value={editedItem?.store?._id || editedItem?.store || ''}
                      onChange={handleInputChange}
                      bg="gray.700"
                      color="white"
                      isDisabled={isStoreExclusive}
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    >
                      {stores.map(store => (
                        <option 
                          key={store._id} 
                          value={store._id}
                          style={{ background: '#1a1a1a', color: 'white' }}
                        >
                          {store.storeName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="white">Image URL</FormLabel>
                    <Input 
                      name="imageURL" 
                      value={editedItem?.imageURL || ''
} 
                      onChange={handleInputChange}
                      placeholder="Enter image URL (optional)"
                      bg="gray.700"
                      color="white"
                      _hover={{ borderColor: 'purple.400' }}
                      _focus={{ 
                        borderColor: 'purple.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                      }}
                    />
                  </FormControl>
                </VStack>

                <Box width="120px" height="120px" flexShrink={0}>
                  <Image 
                    src={imageUrl}
                    alt={editedItem?.itemName} 
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    borderRadius="md"
                    onError={handleImageError}
                  />
                </Box>
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter 
            display="flex" 
            flexDirection="column" 
            width="100%" 
            p={4}
          >
            <Button
              colorScheme="green"
              size="lg"
              width="100%"
              mb={4}
              onClick={handleWantThis}
              isLoading={isUpdating}
              _hover={{ transform: 'translateY(-1px)', boxShadow: 'lg' }}
              _active={{ transform: 'translateY(1px)', boxShadow: 'sm' }}
            >
              I also want this!
            </Button>

            <HStack spacing={2} width="100%">
              {modalButtons()}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
        isCentered
        size={{ base: "sm", md: "md" }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="gray.850">
            <AlertDialogHeader color="white">Delete Item</AlertDialogHeader>
            <AlertDialogBody color="white">
              Are you sure you want to delete "{editedItem?.itemName}"? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button 
                ref={cancelRef} 
                onClick={onDeleteAlertClose}
                variant="ghost"
                color="white"
                _hover={{ bg: 'whiteAlpha.200' }}
              >
                Cancel
              </Button>
              <Button 
                colorScheme="red" 
                onClick={handleDelete} 
                ml={3}
                isLoading={isDeleting}
                _hover={{ bg: 'red.600' }}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Add New Section Modal */}
      <Modal
        isOpen={isSectionModalOpen}
        onClose={onSectionModalClose}
        size="md"
      >
        <ModalOverlay />
        <ModalContent bg="gray.850">
          <ModalHeader color="white">Add New Section</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel color="white">Section Name</FormLabel>
              <Input
                name="sectionName"
                value={newSection.sectionName}
                onChange={handleNewSectionInputChange}
                placeholder="Enter section name"
                bg="gray.700"
                color="white"
                borderColor="gray.600"
                _hover={{ borderColor: 'purple.400' }}
                _focus={{ 
                  borderColor: 'purple.500',
                  boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleAddSection}
              isLoading={isAddingSectionLoading}
              bg="blue.500"
              _hover={{ bg: 'blue.600' }}
            >
              Add Section
            </Button>
            <Button
              variant="ghost"
              onClick={onSectionModalClose}
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ItemModal;