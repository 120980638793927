import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  VStack, 
  useToast, 
  Heading,
  Container,
  InputGroup,
  InputRightElement,
  IconButton
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { checkAndRefreshToken } from '../utils/auth';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuthenticated = await checkAndRefreshToken();
        if (isAuthenticated) {
          const token = localStorage.getItem('token');
          // Verify token is still valid
          const verifyResponse = await axios.get(`${apiBaseUrl}/auth/verify`, {
            headers: { 'x-auth-token': token }
          });
          if (verifyResponse.data.valid) {
            navigate('/');
          }
        }
      } catch (error) {
        // Clear any invalid tokens
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('userId');
      }
    };
    checkAuth();
  }, [navigate, apiBaseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await axios.post(`${apiBaseUrl}/auth/login`, { 
        username: username.trim(), 
        password 
      });

      // Store auth data in localStorage
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('isAdmin', res.data.isAdmin);
      localStorage.setItem('userId', res.data.userId);

      // Show success message
      toast({
        title: "Login Successful",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top"
      });

      // Navigate to main page
      navigate('/');
    } catch (error) {
      // Show error message
      toast({
        title: "Login Failed",
        description: error.response?.data?.msg || "Invalid username or password",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top"
      });

      // Clear form on error
      setPassword('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Box 
        maxWidth="400px" 
        margin="auto" 
        mt={8}
        bg="gray.850"
        p={8}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="gray.700"
        boxShadow="xl"
      >
        <VStack spacing={6} align="stretch">
          <Heading 
            size="lg" 
            textAlign="center" 
            color="white" 
            mb={4}
          >
            Welcome Back
          </Heading>

          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel color="gray.300">Username</FormLabel>
                <Input 
                  type="text" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)}
                  bg="gray.700"
                  color="white"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'purple.400' }}
                  _focus={{ 
                    borderColor: 'purple.500',
                    boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                  }}
                  placeholder="Enter your username"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel color="gray.300">Password</FormLabel>
                <InputGroup>
                  <Input 
                    type={showPassword ? "text" : "password"}
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)}
                    bg="gray.700"
                    color="white"
                    borderColor="gray.600"
                    _hover={{ borderColor: 'purple.400' }}
                    _focus={{ 
                      borderColor: 'purple.500',
                      boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)'
                    }}
                    placeholder="Enter your password"
                  />
                  <InputRightElement>
                    <IconButton
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      variant="ghost"
                      color="gray.400"
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label={showPassword ? "Hide password" : "Show password"}
                      _hover={{ bg: 'whiteAlpha.200' }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button 
                type="submit" 
                colorScheme="purple" 
                width="full"
                size="lg"
                isLoading={isLoading}
                loadingText="Logging in..."
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                _active={{
                  transform: 'translateY(0)',
                  boxShadow: 'md',
                }}
              >
                Login
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
    </Container>
  );
}

export default Login;