import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Grid,
  Select,
  Text,
  VStack,
  InputGroup,
  InputLeftElement,
  useToast,
  Input,
  useDisclosure,
  HStack,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import io from 'socket.io-client';
import axios from 'axios';
import AddItem from './AddItem';
import ItemCard from './ItemCard';
import ItemModal from './ItemModal';

// Controls section component
const ListControls = ({ 
  searchTerm, 
  setSearchTerm, 
  selectedSection,
  setSelectedSection,
  sections,
  onItemAdded 
}) => {
  return (
    <VStack spacing={4} width="100%">
      <Heading
        size="2xl"
        color="white"
        mb={4}
        textAlign="center"
        fontWeight="bold"
      >
        ITEMS LIST
      </Heading>
      
      {/* Search Bar - Full Width */}
      <Box width="100%">
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            bg="gray.700"
            color="white"
            _placeholder={{ color: 'gray.400' }}
            borderRadius="md"
          />
        </InputGroup>
      </Box>

      {/* Equal Width Controls */}
      <HStack width="100%" spacing={4}>
        {/* Sections Dropdown - 1/2 Width */}
        <Box flex="1">
          <Select
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
            bg="gray.700"
            color="white"
            size="lg"
            borderRadius="md"
            sx={{
              '& option': {
                color: 'white',
                backgroundColor: 'gray.700'
              }
            }}
          >
            <option value="all">All Sections</option>
            {sections.sort((a, b) => a.sectionName.localeCompare(b.sectionName)).map(section => (
              <option key={section._id} value={section._id}>
                {section.sectionName}
              </option>
            ))}
          </Select>
        </Box>

        {/* Add New Item Button - 1/2 Width */}
        <Box flex="1">
          <AddItem onItemAdded={onItemAdded} />
        </Box>
      </HStack>
    </VStack>
  );
};

// ItemGroup component for displaying items in a section
const ItemGroup = ({ title, items, onItemClick, onStatusChange, onFavoriteChange }) => {
  if (items.length === 0) return null;

  return (
    <Box mb={8}>
      <Box 
        py={2} 
        px={4} 
        mb={4}
        bg="gray.800"
        borderRadius="md"
        borderLeft="4px solid"
        borderLeftColor="purple.500"
      >
        <Heading size="md" color="white">
          {title}
        </Heading>
      </Box>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)"
        }}
        gap={6}
      >
        {items.map(item => (
          <ItemCard
            key={item._id}
            item={item}
            onClick={() => onItemClick(item)}
            onStatusChange={onStatusChange}
            onFavoriteChange={onFavoriteChange}
          />
        ))}
      </Grid>
      <Divider mt={8} borderColor="gray.700" />
    </Box>
  );
};

function PantryList() {
  const [items, setItems] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState({ favorites: [], allItems: [] });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const userId = localStorage.getItem('userId');

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setItems(response.data.filter(item => item.status === 'Have'));
    } catch (error) {
      console.error('Error fetching items:', error);
      toast({
        title: "Error fetching items",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [apiBaseUrl, toast]);

  const fetchSections = useCallback(async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/sections`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSections(response.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
      toast({
        title: "Error fetching sections",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [apiBaseUrl, toast]);

  const filterAndSortItems = useCallback(() => {
    let filtered = [...items];

    // Apply section filter if selected
    if (selectedSection !== 'all') {
      filtered = filtered.filter(item => item.section?._id === selectedSection);
    }

    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Split into favorites and non-favorites
    const favorites = filtered.filter(item => 
      item.favorites?.includes(userId)
    ).sort((a, b) => a.itemName.localeCompare(b.itemName));

    const allItems = filtered.filter(item => 
      !item.favorites?.includes(userId)
    ).sort((a, b) => a.itemName.localeCompare(b.itemName));

    setFilteredItems({ favorites, allItems });
  }, [items, selectedSection, searchTerm, userId]);

  useEffect(() => {
    const socket = io(apiBaseUrl);

    socket.on('itemAdded', (newItem) => {
      if (newItem.status === 'Have') {
        setItems(prevItems => [...prevItems, newItem]);
      }
    });

    socket.on('itemUpdated', (updatedItem) => {
      setItems(prevItems => {
        if (updatedItem.status === 'Need') {
          return prevItems.filter(item => item._id !== updatedItem._id);
        }
        return prevItems.map(item => 
          item._id === updatedItem._id ? updatedItem : item
        );
      });
    });

    socket.on('itemDeleted', (itemId) => {
      setItems(prevItems => prevItems.filter(item => item._id !== itemId));
    });

    return () => {
      socket.disconnect();
    };
  }, [apiBaseUrl]);

  useEffect(() => {
    fetchItems();
    fetchSections();
  }, [fetchItems, fetchSections]);

  useEffect(() => {
    filterAndSortItems();
  }, [filterAndSortItems]);

  const handleStatusChange = async (itemId, newStatus) => {
    try {
      const currentItem = items.find(item => item._id === itemId);
      
      const response = await axios.put(
        `${apiBaseUrl}/items/${itemId}`,
        { 
          status: newStatus,
          favorites: currentItem.favorites
        },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      
      if (newStatus === 'Need') {
        setItems(prevItems => prevItems.filter(item => item._id !== itemId));
      } else {
        setItems(prevItems =>
          prevItems.map(item =>
            item._id === itemId ? response.data : item
          )
        );
      }

      toast({
        title: `Item marked as ${newStatus}`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleItemUpdate = async (updatedItem) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/items/${updatedItem._id}`,
        updatedItem,
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      if (response.data.status === 'Have') {
        setItems(prevItems =>
          prevItems.map(item =>
            item._id === updatedItem._id ? response.data : item
          )
        );
      } else {
        setItems(prevItems =>
          prevItems.filter(item => item._id !== updatedItem._id)
        );
      }

      toast({
        title: "Item updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleItemDelete = async (itemId) => {
    try {
      await axios.delete(`${apiBaseUrl}/items/${itemId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });

      setItems(prevItems => prevItems.filter(item => item._id !== itemId));

      toast({
        title: "Item deleted successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleFavoriteChange = () => {
    fetchItems();
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <ListControls
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          sections={sections}
          onItemAdded={(newItem) => {
            if (newItem.status === 'Have') {
              setItems(prevItems => [...prevItems, newItem]);
            }
          }}
        />

        {filteredItems.favorites.length === 0 && filteredItems.allItems.length === 0 ? (
          <Text color="gray.400" textAlign="center" py={8}>
            No items in pantry. Items marked as "Have" will appear here.
          </Text>
        ) : (
          <>
            <ItemGroup
              title="Favorites"
              items={filteredItems.favorites}
              onItemClick={(item) => {
                setSelectedItem(item);
                onOpen();
              }}
              onStatusChange={handleStatusChange}
              onFavoriteChange={handleFavoriteChange}
            />

            <ItemGroup
              title="All Items"
              items={filteredItems.allItems}
              onItemClick={(item) => {
                setSelectedItem(item);
                onOpen();
              }}
              onStatusChange={handleStatusChange}
              onFavoriteChange={handleFavoriteChange}
            />
          </>
        )}

        {selectedItem && (
          <ItemModal
            isOpen={isOpen}
            onClose={() => {
              setSelectedItem(null);
              onClose();
            }}
            item={selectedItem}
            onUpdate={handleItemUpdate}
            onDelete={handleItemDelete}
          />
        )}
      </VStack>
    </Container>
  );
}

export default PantryList;