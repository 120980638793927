import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Heading,
  Divider,
  Text,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Container,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';

function AdminDashboard() {
  // State management
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Modal controls
  const {
    isOpen: isUserModalOpen,
    onOpen: onUserModalOpen,
    onClose: onUserModalClose
  } = useDisclosure();

  const {
    isOpen: isStoreModalOpen,
    onOpen: onStoreModalOpen,
    onClose: onStoreModalClose
  } = useDisclosure();

  const {
    isOpen: isSectionModalOpen,
    onOpen: onSectionModalOpen,
    onClose: onSectionModalClose
  } = useDisclosure();

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose
  } = useDisclosure();

  const toast = useToast();
  const cancelRef = React.useRef();

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  // Initial data fetch
  useEffect(() => {
    fetchUsers();
    fetchStores();
    fetchSections();
  }, []);

  // Fetch functions
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/users`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast({
        title: "Error fetching users",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchStores = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/stores`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStores(response.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
      toast({
        title: "Error fetching stores",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchSections = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/sections`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSections(response.data);
    } catch (error) {
      console.error('Error fetching sections:', error);
      toast({
        title: "Error fetching sections",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // User handlers
  const handleAddUser = () => {
    setSelectedUser({ username: '', password: '', isAdmin: false });
    setIsEditing(false);
    onUserModalOpen();
  };

  const handleEditUser = (user) => {
    setSelectedUser({ ...user, password: '' });
    setIsEditing(true);
    onUserModalOpen();
  };

  const handleSaveUser = async () => {
    try {
      setIsLoading(true);
      if (isEditing) {
        await axios.put(
          `${apiBaseUrl}/users/${selectedUser._id}`,
          selectedUser,
          { headers: { 'x-auth-token': localStorage.getItem('token') }}
        );
        toast({
          title: "User updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await axios.post(
          `${apiBaseUrl}/users`,
          selectedUser,
          { headers: { 'x-auth-token': localStorage.getItem('token') }}
        );
        toast({
          title: "User added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      onUserModalClose();
      fetchUsers();
    } catch (error) {
      toast({
        title: "Error saving user",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Store handlers
  const handleAddStore = () => {
    setSelectedStore({ 
      storeName: '', 
      shortName: '',
      color: '#000000' 
    });
    setIsEditing(false);
    onStoreModalOpen();
  };

  const handleEditStore = (store) => {
    setSelectedStore(store);
    setIsEditing(true);
    onStoreModalOpen();
  };

  const handleSaveStore = async () => {
    try {
      setIsLoading(true);
      if (isEditing) {
        await axios.put(
          `${apiBaseUrl}/stores/${selectedStore._id}`,
          selectedStore,
          { headers: { 'x-auth-token': localStorage.getItem('token') }}
        );
        toast({
          title: "Store updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await axios.post(
          `${apiBaseUrl}/stores`,
          selectedStore,
          { headers: { 'x-auth-token': localStorage.getItem('token') }}
        );
        toast({
          title: "Store added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      onStoreModalClose();
      fetchStores();
    } catch (error) {
      toast({
        title: "Error saving store",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Section handlers
  const handleAddSection = () => {
    setSelectedSection({ sectionName: '' });
    setIsEditing(false);
    onSectionModalOpen();
  };

  const handleEditSection = (section) => {
    setSelectedSection(section);
    setIsEditing(true);
    onSectionModalOpen();
  };

  const handleSaveSection = async () => {
    try {
      setIsLoading(true);
      if (!selectedSection.sectionName) {
        toast({
          title: "Section name is required",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (isEditing) {
        await axios.put(
          `${apiBaseUrl}/sections/${selectedSection._id}`,
          { sectionName: selectedSection.sectionName },
          { headers: { 'x-auth-token': localStorage.getItem('token') }}
        );
        toast({
          title: "Section updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await axios.post(
          `${apiBaseUrl}/sections`,
          { sectionName: selectedSection.sectionName },
          { headers: { 'x-auth-token': localStorage.getItem('token') }}
        );
        toast({
          title: "Section added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      onSectionModalClose();
      fetchSections();
    } catch (error) {
      toast({
        title: "Error saving section",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Delete handlers
  const handleDeleteClick = (item, type) => {
    setItemToDelete(item);
    setDeleteType(type);
    onDeleteAlertOpen();
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      switch (deleteType) {
        case 'user':
          await axios.delete(`${apiBaseUrl}/users/${itemToDelete._id}`, {
            headers: { 'x-auth-token': localStorage.getItem('token') }
          });
          fetchUsers();
          break;
        case 'store':
          await axios.delete(`${apiBaseUrl}/stores/${itemToDelete._id}`, {
            headers: { 'x-auth-token': localStorage.getItem('token') }
          });
          fetchStores();
          break;
        case 'section':
          await axios.delete(`${apiBaseUrl}/sections/${itemToDelete._id}`, {
            headers: { 'x-auth-token': localStorage.getItem('token') }
          });
          fetchSections();
          break;
        default:
          console.error('Unknown delete type:', deleteType);
          return;
      }
      toast({
        title: `${deleteType.charAt(0).toUpperCase() + deleteType.slice(1)} deleted successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onDeleteAlertClose();
    } catch (error) {
      console.error(`Error deleting ${deleteType}:`, error);
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={8} align="stretch">
        {/* Users Section */}
        <Box>
          <HStack justify="space-between" mb={4}>
            <Heading size="lg" color="white">Users</Heading>
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddUser}
              colorScheme="blue"
              size="md"
            >
              Add New User
            </Button>
          </HStack>

          <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor="gray.700">
            <Table variant="simple">
              <Thead bg="gray.800">
                <Tr>
                  <Th color="gray.300">Username</Th>
                  <Th color="gray.300">Admin Status</Th>
                  <Th color="gray.300">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map(user => (
                  <Tr key={user._id} _hover={{ bg: 'gray.700' }}>
                    <Td color="white">{user.username}</Td>
                    <Td>
                      <Badge
                        colorScheme={user.isAdmin ? "green" : "gray"}
                        px={2}
                        py={1}
                        borderRadius="full"
                      >
                        {user.isAdmin ? 'Admin' : 'User'}
                      </Badge>
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleEditUser(user)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDeleteClick(user, 'user')}
                        >
                          Delete
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Divider />

        {/* Stores Section */}
        <Box>
          <HStack justify="space-between" mb={4}>
            <Heading size="lg" color="white">Stores</Heading>
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddStore}
              colorScheme="blue"
              size="md"
            >
              Add New Store
            </Button>
          </HStack>

          <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor="gray.700">
            <Table variant="simple">
              <Thead bg="gray.800">
                <Tr>
                  <Th color="gray.300">Store Name</Th>
                  <Th color="gray.300">Short Name</Th>
                  <Th color="gray.300">Color</Th>
                  <Th color="gray.300">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {stores.map(store => (
                  <Tr key={store._id} _hover={{ bg: 'gray.700' }}>
                    <Td color="white">{store.storeName}</Td>
                    <Td color="white">{store.shortName}</Td>
                    <Td>
                      <HStack>
                        <Box
                          w="24px"
                          h="24px"
                          borderRadius="md"
                          bg={store.color}
                          borderWidth="1px"
                          borderColor="gray.500"
                        />
                        <Text color="white">{store.color}</Text>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleEditStore(store)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDeleteClick(store, 'store')}
                        >
                          Delete
                        </Button>
                      </HStack>
</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Divider />

        {/* Sections */}
        <Box>
          <HStack justify="space-between" mb={4}>
            <Heading size="lg" color="white">Sections</Heading>
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddSection}
              colorScheme="blue"
              size="md"
            >
              Add New Section
            </Button>
          </HStack>

          <Box overflowX="auto" borderRadius="lg" borderWidth="1px" borderColor="gray.700">
            <Table variant="simple">
              <Thead bg="gray.800">
                <Tr>
                  <Th color="gray.300">Section Name</Th>
                  <Th color="gray.300" isNumeric>Items Count</Th>
                  <Th color="gray.300">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
		{/* Sections Table Body */}
{sections.map(section => (
  <Tr key={section._id} _hover={{ bg: 'gray.700' }}>
    <Td color="white">{section.sectionName}</Td>
    <Td isNumeric>
      <Badge
        bg="purple.500"
        px={3}
        py={1}
        borderRadius="full"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        boxShadow="md"
        _hover={{
          transform: 'scale(1.05)',
          transition: 'transform 0.2s'
        }}
      >
        <Text
          color="yellow.200"
          fontSize="sm"
          fontWeight="bold"
        >
          {section.itemCount || 0}
        </Text>
      </Badge>
    </Td>
    <Td>
      <HStack spacing={2}>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={() => handleEditSection(section)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          colorScheme="red"
          onClick={() => handleDeleteClick(section, 'section')}
        >
          Delete
        </Button>
      </HStack>
    </Td>
  </Tr>
))}


              </Tbody>
            </Table>
          </Box>
        </Box>

        {/* User Modal */}
        <Modal isOpen={isUserModalOpen} onClose={onUserModalClose}>
          <ModalOverlay />
          <ModalContent bg="gray.850">
            <ModalHeader color="white">
              {isEditing ? 'Edit User' : 'Add New User'}
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel color="white">Username</FormLabel>
                  <Input
                    value={selectedUser?.username || ''}
                    onChange={(e) => setSelectedUser({...selectedUser, username: e.target.value})}
                    bg="gray.700"
                    color="white"
                  />
                </FormControl>
                <FormControl isRequired={!isEditing}>
                  <FormLabel color="white">
                    {isEditing ? 'New Password (leave blank to keep current)' : 'Password'}
                  </FormLabel>
                  <Input
                    type="password"
                    value={selectedUser?.password || ''}
                    onChange={(e) => setSelectedUser({...selectedUser, password: e.target.value})}
                    bg="gray.700"
                    color="white"
                  />
                </FormControl>
                <FormControl>
                  <Checkbox
                    isChecked={selectedUser?.isAdmin || false}
                    onChange={(e) => setSelectedUser({...selectedUser, isAdmin: e.target.checked})}
                  >
                    <Text color="white">Is Admin</Text>
                  </Checkbox>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button 
                colorScheme="blue" 
                mr={3} 
                onClick={handleSaveUser}
                isLoading={isLoading}
              >
                {isEditing ? 'Update' : 'Add'}
              </Button>
              <Button variant="ghost" onClick={onUserModalClose} color="white">
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Store Modal */}
        <Modal isOpen={isStoreModalOpen} onClose={onStoreModalClose}>
          <ModalOverlay />
          <ModalContent bg="gray.850">
            <ModalHeader color="white">
              {isEditing ? 'Edit Store' : 'Add New Store'}
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel color="white">Store Name</FormLabel>
                  <Input
                    value={selectedStore?.storeName || ''}
                    onChange={(e) => setSelectedStore({...selectedStore, storeName: e.target.value})}
                    bg="gray.700"
                    color="white"
                    placeholder="Full store name (e.g., BJ's Wholesale)"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel color="white">Short Name</FormLabel>
                  <Input
                    value={selectedStore?.shortName || ''}
                    onChange={(e) => setSelectedStore({...selectedStore, shortName: e.target.value.toUpperCase()})}
                    bg="gray.700"
                    color="white"
                    placeholder="Short name (e.g., BJ'S)"
                    textTransform="uppercase"
                  />
                  <Text fontSize="sm" color="gray.400" mt={1}>
                    Short name will be displayed on item cards. Keep it brief and clear.
                  </Text>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel color="white">Store Color</FormLabel>
                  <HStack>
                    <Input
                      type="color"
                      value={selectedStore?.color || '#000000'}
                      onChange={(e) => setSelectedStore({...selectedStore, color: e.target.value})}
                      bg="gray.700"
                      w="100px"
                      p={1}
                    />
                    <Input
                      value={selectedStore?.color || '#000000'}
                      onChange={(e) => setSelectedStore({...selectedStore, color: e.target.value})}
                      bg="gray.700"
                      color="white"
                      placeholder="#000000"
                    />
                  </HStack>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button 
                colorScheme="blue" 
                mr={3} 
                onClick={handleSaveStore}
                isLoading={isLoading}
              >
                {isEditing ? 'Update Store' : 'Add Store'}
              </Button>
              <Button variant="ghost" onClick={onStoreModalClose} color="white">
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Section Modal */}
        <Modal isOpen={isSectionModalOpen} onClose={onSectionModalClose}>
          <ModalOverlay />
          <ModalContent bg="gray.850">
            <ModalHeader color="white">
              {isEditing ? 'Edit Section' : 'Add New Section'}
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel color="white">Section Name</FormLabel>
                  <Input
                    value={selectedSection?.sectionName || ''}
                    onChange={(e) => setSelectedSection({
                      ...selectedSection,
                      sectionName: e.target.value
                    })}
                    bg="gray.700"
                    color="white"
                    placeholder="Section name (e.g., Pantry)"
                  />
                </FormControl>

                {isEditing && (
                  <Box w="100%" p={4} bg="gray.900" borderRadius="md">
                    <Text fontSize="sm" color="yellow.200">
                      Note: Updating section information will affect all items associated with this section.
                    </Text>
                  </Box>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button 
                colorScheme="blue" 
                mr={3} 
                onClick={handleSaveSection}
                isLoading={isLoading}
              >
                {isEditing ? 'Update Section' : 'Add Section'}
              </Button>
              <Button variant="ghost" onClick={onSectionModalClose} color="white">
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Delete Confirmation Dialog */}
        <AlertDialog
          isOpen={isDeleteAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDeleteAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent bg="gray.850">
              <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
                Confirm Delete
              </AlertDialogHeader>
              <AlertDialogBody>
                <VStack align="stretch" spacing={3}>
                  <Text color="white">
                    Are you sure you want to delete this {deleteType}?
                  </Text>
                  {deleteType === 'section' && (
                    <>
                      <Text color="red.300" fontWeight="bold">
                        Section: {itemToDelete?.sectionName}
                      </Text>
                      <Text color="yellow.200" fontSize="sm">
                        Warning: This will only work if no items are associated with this section. 
                        You must first delete or reassign all items linked to this section.
                      </Text>
                    </>
                  )}
                  <Text color="orange.200" fontSize="sm">
                    This action cannot be undone.
                  </Text>
                </VStack>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button 
                  ref={cancelRef} 
                  onClick={onDeleteAlertClose}
                  variant="ghost"
                  color="white"
                >
                  Cancel
                </Button>
                <Button 
                  colorScheme="red" 
                  onClick={handleDelete} 
                  ml={3}
                  isLoading={isLoading}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </VStack>
    </Container>
  );
}

export default AdminDashboard;
